import { Component } from '@angular/core';
import { DialogService } from '@/ux/services/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { UpdatemeFormComponent } from '../../components/updateme-form/updateme-form.component';
import { PasswordFormComponent } from '@/auth/components/password-form/password-form.component';
import { NotificationsConfigComponent } from '@/auth/components/notifications-config/notifications-config.component';
import { UsersService } from '@/admin/services/users.service';
import { User } from '@/core/models/user';
import { SharedModule } from '@/shared/shared.module';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  host: { class: 'page' },
  imports: [PasswordFormComponent, NotificationsConfigComponent, SharedModule],
})
export class ProfileComponent {
  user: User;
  configs: any;
  user$: Observable<User>;

  constructor(
    public auth: AuthService,
    private dialog: DialogService,
    private users: UsersService,
  ) {
    this.user$ = this.users.one(this.auth.user.id).pipe(
      map((user: any) => {
        user.fullname = `${user.name || 'Unamed'} ${user.surname || ''}`;
        user.configs = JSON.parse(user.configs);
        if (user.phones) JSON.parse(user.phones);
        return user;
      }),
    );
  }

  edit() {
    const ref = this.dialog.open(UpdatemeFormComponent, {
      classes: 'without-min',
      inputs: { data: this.auth.user },
    });
    ref.events.subscribe((event) => {
      if (event.type === 'beforeclose') this.auth.validate().subscribe();
    });
  }
}
