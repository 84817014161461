import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@/ux/services/dialog';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsComponent } from 'src/app/shared/components/notifications/notifications.component';

@Component({
  selector: 'app-bridge',
  template: '<div> Redirigiendo... </div>',
  styles: [],
})
export class BridgeComponent {
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private dialog: DialogService,
  ) {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(map(([params, query]) => ({ ...query, ...params })))
      .subscribe((params) => this.handler(params));
  }

  handler(params: Record<string, any>) {
    const { to, id, date } = params;
    switch (to) {
      case 'tickets':
        this.router.navigate(['/operations/mytickets'], {
          queryParams: { date, id },
        });
        break;
      case 'notifications':
        this.router.navigate(['/operations/mytickets']).then(() => {
          this.dialog.open(NotificationsComponent, {
            type: 'shy',
          });
        });
        break;
      default:
        this.router.navigate(['/operations/mytickets']);
        break;
    }
  }
}
